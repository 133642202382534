<template>
	<Row>
		<Column>
			<Box>
				<h1>Version One</h1>
			</Box>
		</Column>
	</Row>
</template>

<script lang="ts" setup>
let { data } = await useFetch<User[]> ( '/api/users' )
</script>

<style></style>

